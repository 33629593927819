/*New classes*/

.for-mobile {
  display: none;
}

#super-user-menu {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  height: auto;
  background-color: #fff;
  z-index: 1031;
  padding-top: 32px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);

  ul {
    margin: 0;
  }

  li {
    list-style: none;
    margin-bottom: 11px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }
}

.super-user-menu-wide {
  width: auto !important;
}

.super-user-icon {
  height: 48px;
  min-width: 48px;
  width: 100%;
  background-color: #fff;
  color: rgba(0, 70, 102, 1);
  border-radius: 25px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  margin-left: 11px;
  margin-right: 11px;
  text-decoration: none;

  &:hover {
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.4);
    border-color: #4c5356;
    outline: 0;
  }
}

.super-user-icon-active {
  background-color: rgba(0, 70, 102, 1);
  color: #ffffff !important;

  .super-user-icon-text {
    color: #ffffff !important;
  }

  &:hover {
    box-shadow: 0 2px 5px 1px #fff;
    outline: 0;
  }
}

.super-user-icon-admin {
  height: 48px;
  min-width: 48px;
  width: 100%;
  background-color: #fff;
  color: rgba(0, 70, 102, 1);
  border-radius: 25px;
  border: 1px solid #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  margin-left: 11px;
  margin-right: 11px;
  text-decoration: none;

  &:hover .super-user-icon-text {
    text-decoration: underline;
  }
}

.super-user-text-admin {
  margin-left: 11px;
  margin-right: 11px;
  height: 32px;
  width: 100%;
}

.super-user-icon-text {
  font-size: 0.83333rem;
  text-transform: uppercase;
  font-family: "vwfs-sans";
  font-weight: normal;
  color: rgba(0, 70, 102, 1);
  margin-left: 9px;
  margin-right: 3px;
  margin-top: 2px;
}

.super-user-name-icon {
  height: 48px;
  width: 48px;
  background-color: #fff;
  border-radius: 25px;
  color: rgba(0, 70, 102, 1);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  margin-left: 11px;
  margin-right: 11px;
  text-decoration: none;
  margin-bottom: 100px;
  border: 1px solid rgba(0, 70, 102, 1);
}

.super-user-menu-wide .super-user-name-section {
  background-color: #f2f2f2;
}

.super-user-name-section {
  padding-top: 24px;

  .super-user-icon-text {
    margin-left: 0;
  }

  a {
    .super-user-icon-text {
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.page-margin {
  padding-left: 100px;
}

.bg-blue {
  background-color: rgba(0, 70, 102, 1);
}

.bg-blue-90 {
  background-color: rgba(0, 70, 102, 0.9);
}

.bg-blue-10 {
  background-color: rgba(0, 70, 102, 0.1);
}

.bg-cover {
  height: 375px;
  display: block;
}

.bg-cover-small {
  max-height: 310px;
}

.bg-cover-medium {
  max-height: 400px;
}

.bg-cover-big {
  max-height: 500px;
}

.badge {
  border-radius: 0rem;
  text-align: center;
  font-size: 0.9rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  line-height: 1.5rem;
  padding-top: 0.26rem;
  padding-bottom: 0.26rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &.badge-small {
    font-size: 0.75rem;
    font-weight: 400;
    min-height: 1.3rem;
    min-width: 1.3rem;
    line-height: 1.4rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  &.badge-round {
    border-radius: 0.8rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    padding: 0;
  }

  &.badge-info {
    color: #fff;
    background-color: #004666;
  }

  &.badge-info-inverse {
    color: #004666;
    background-color: #fff;
  }

  &.badge-success {
    color: #fff;
    background-color: #05ce9f;
  }

  &.badge-warning {
    color: #4c5356;
    background-color: #f5e850;
  }

  &.badge-danger {
    color: #fff;
    background-color: #ff4963;
  }
}

.close-notification-btn {
  margin-left: 0.5rem;
}

.c-checkbox__label::before {
  font-family: "bronson-bluelabel" !important;
  content: "\e9b9";
}

.text-blue {
  color: #004666;
}

.text-grey {
  color: #4c5356;
}

.text-light-grey {
  color: #A8ADB3;
}

.text-red {
  color: #ff4963;
}

small {
  &.is-error {
    color: #ff4963;
  }

  margin-top: 0.5rem;
  display: block;
  line-height: 1.4;
}

.c-input__input.is-error {
  box-shadow: 0 0 0 1px #ff4963 inset;
  color: #ff4963;
}

.show-cursor {
  cursor: pointer;
}

.input-change {
  color: #004666 !important;
  border-color: #004666 !important;
}

.btn-notifications {
  padding: 0.5rem;
  background-color: #66e4ee;
}

.custom-select-vw {
  color: #a8adb3;
}

.biluppgifter li, .user-details li, .extrautrustning li, .forare-personuppgifter li {
  list-style: none;
  margin-bottom: 0.3rem;
}

.biluppgifter, .user-details, .extrautrustning {
  margin-left: 0;
}

.address-details {
  margin-left: 0;

  li {
    list-style: none;
    margin-bottom: 0.1rem;
  }
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-dark-grey {
  background-color: #4c5356;
}

.red-border {
  border: 0.2rem solid #ff4963;
}

.cell-border td {
  border-bottom: solid 2px #f2f2f2;
}

.blue-border {
  border-bottom: solid 2px #004666 !important;
}

.blue-border-all {
  border: solid 2px #004666 !important;
}

.mobile-btn-bar {
  z-index: 3;
}

.table-skatt {
  text-align: left;

  td, th {
    padding-left: 0.5rem;
  }
}

.login-modal-list {
  list-style: none;
  text-align: left;
  margin-left: 0;

  li {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
}

.small {
  font-size: 0.75rem;
}

.text-decoration-none {
  text-decoration: none !important;
}

.c-btn--minimal {
  min-height: auto;
}

.message .c-card {
  background-color: transparent;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  text-align: left;
  border: 0;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
}

.notification, .message {
  margin-bottom: 0.5rem;
}

.verksamhetsbilar td {
  vertical-align: middle !important;
}

.step {
  min-height: 400px !important;
}

.search-icon {
  background: url(../images/search-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

#mobile-icon-rotation, #mobile-text-rotation {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-in-text {
  animation-name: fadein;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
  }
}


.rotate-icon {
  animation-name: rotate;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.4s;
  animation-delay: 0.6s;
  animation-timing-function: ease-in;
}

/*Rapporter*/

.report-filter .c-extra-content::before {
  background-color: transparent;
}

.report-table {
  table {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 0.85rem;

    tr {
      td {
        border-top: 1px solid rgb(76, 83, 86);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      &:first-child td {
        border-top: 0;
        border-bottom: 1px solid rgb(128, 128, 128);
      }
    }
  }

  button {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #004666;
  }
}

/*
  .report-table button::after {
      margin-left: 0.2rem;
      font-family:"bronson-bluelabel" !important;
      content: "\e9b9";
  }*/

#change-date-picker .calendar-icon-disabled::after {
  background-color: #f2f2f2;
}

.report-table {
  .active {
    font-weight: bold;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #004666;
    }
  }
}

/*Billistan*/

.circle-icon {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #ffffff;
  border: solid 1px rgba(0, 70, 102, 0.9);

  &:focus {
    outline: 0;
    border: solid 2px rgba(0, 70, 102, 0.9);
  }
}

.list-circle-icon {
  background: url(../images/list-icon-inactive.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.active-list-view.list-circle-icon {
  background: url(../images/list-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.card-circle-icon {
  background: url(../images/card-icon-inactive.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.copy-icon-small {
  background: url(../images/copy.svg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 1rem !important;
  height: 1rem !important;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    transform: perspective(200px);
    opacity: 1;
  }
}

.active-list-view {
  &.card-circle-icon {
    background: url(../images/card-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  border: solid 2px rgba(0, 70, 102, 0.9);
}

.car-list-item {
  margin-bottom: 1rem;
  padding: 0.8rem;
}

.link-min-area {
  min-height: 0;
}

.link-separator {
  border-right: solid 1px #cccccc;
  margin-right: 0.5rem;
  margin-left: 0.3rem;
}

.min-width-1200 {
  min-width: 1200px !important;
}

.border-bottom {
  border-bottom: solid 1px #4c5356;
}

.report-mobile-btn {
  display: none;
}
/*Message modal*/

.message-modal {
  width: 100%;
  height: 100%;
  top: 8%;
  transform: translate(-50%);

  .c-modal__content {
    max-height: 65vh;
  }
}
/*Avrop*/

.list-btn {
  width: 100%;
  background-color: #f2f2f2;
  border: 0px;
  color: #004666;
  text-align: left;

  &:hover {
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.4);
    outline: 0;
  }
}

.no-hover:hover {
  box-shadow: 0 0 0 0 !important;
}

.list-btn:focus {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.4);
  outline: 0;
}

.add-dealer-btn {
  color: #004666;
  text-decoration: none;
  background-color: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
}

.active-dealer, .active-driver, .active-list-btn {
  box-shadow: 0px 0px 0px 2px #004666 inset !important;
}

.c-brand-pictogram {
  display: inline-block;
  width: 180px;
  height: 68px;
  padding-top: 23px;
  position: absolute;
  top: 0;
  left: 200px;
}

.calendar-icon-disabled {
  border-color: #d8d8d8;
  background-color: #f2f2f2;
  color: #a8adb3;
  cursor: default;
  pointer-events: none;
}

.empty-state i {
  font-size: 3rem;
}
/*New classes END*/
/*Overwirte*/
/*Logo ver 2
  
  .c-brand-logo {
      width: 380px;
  }
  
  .c-brand-logo svg {
      width:340px;
  }
  
  */
/*Logo ver 3
  
  .c-brand-logo {
      height: 138px;
  }
  
  .c-brand-logo svg {
      height: 140px;
  }
  
  
  .c-hero-teaser__content {
      padding-top: 175px;
  }*/

.c-hero-teaser__content {
  padding-top: 190px;
}

.c-brand-pictogram {
  width: 176px;
  height: 55px;
  padding-top: 11px;
  position: absolute;
  top: 127px;
  left: 4px;
}

a:hover {
  text-decoration: none;
}

#login {
  padding-bottom: 3rem;
}

.c-table-wrapper table {
  border-spacing: 0 0;
}

.c-extra-content {
  &::before {
    width: 100%;
    transition: 125ms;
  }

  top: 1rem;
  transition: 650ms;

  &.is-closed {
    top: 3rem;
    margin-bottom: 0rem;

    .collapse {
      visibility: collapse;
    }
  }
}

.rapporter {
  width: 60%;
}

.c-modal {
  display: none;
}

.c-mega-menu__link--level-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
}

.c-page-head-edit {
  top: 40px;
}

.c-hero-teaser__content-edit {
  padding-top: 240px;
}

@media (min-width: 721px) {
  h1 {
    font-size: 2.66667rem;
  }

  .c-hero-teaser__subtitle {
    font-size: 1.22222rem;
  }
}
/*overwrite END*/

.show-on-tablet{
  display: none;
}

@media (max-width: 721px) {
  /*.c-brand-logo {
            height: 100px;
        }
    
        .c-brand-logo svg {
            height: 100px;
        }
    */
  .c-hero-teaser__content {
    padding-top: 120px;
  }

  .c-brand-pictogram {
    width: 166px;
    height: 55px;
    padding-top: 11px;
    position: absolute;
    top: 64px;
    left: 4px;
  }

  .car-text {
    font-size: 0.8rem !important;
  }

  .full-width-mobile {
    width: 100%;
  }

  .hide-when-small {
    display: none !important;
  }

  .c-page-head-nav__link-text::before {
    top: 3px !important;
    left: 15px !important;
  }

  .rapporter {
    width: 100%;
  }

  .no-flex-mobile {
    display: inline-block !important;
  }

  .konto .c-card {
    margin-left: 0 !important;
  }

  .show-on-tablet{
    display: block;
  }

  .car-list-btn, .report-table, .hide-on-tablet {
    display: none;
  }

  .list-btn {
    .text-right {
      text-align: left !important;
    }

    .text-grey {
      font-size: 0.75rem !important;
    }
  }

  .c-table-wrapper:not(.c-table-wrapper--no-scroll) {
    min-width: auto !important;
  }
}

@media (max-width: 480px) {
  .c-hero-teaser__content {
    padding-top: 50px;
  }

  .c-page-head-nav__item:not(:first-child) {
    margin-left: 15px;
  }

  .c-hero-teaser__image {
    max-height: 200px;
  }

  .login-links {
    display: block !important;

    .c-btn {
      margin-top: 1rem;
      width: 100%;
    }
  }

  #login {
    padding-bottom: 0;
  }

  .hero-title {
    margin-top: (-28px) !important;
    position: relative;
  }

  .report-nav-btn {
    min-width: 40%;
  }

  .c-hero-teaser__content {
    margin-bottom: 0px;
  }

  .bg-cover, .bg-cover-small, .bg-cover-medium, .bg-cover-big {
    height: 48px !important;
    margin-bottom: 52px !important;
  }

  .badge-car {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-size: 0.8rem !important;
  }

  .rapporter {
    font-size: 0.75rem !important;
    width: 100%;

    .c-btn--small {
      font-size: 0.75rem !important;
    }
  }

  #rapportera .c-card {
    padding: 1rem !important;
  }

  .konto {
    margin: 0;
  }

  .car-list-btn, .report-table, .hide-on-mobile {
    display: none;
  }

  .report-mobile-btn {
    display: block;
  }

  .report-filter-btn {
    display: none;
  }
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

table .w-5 {
  width: 5%;
}

table .w-7 {
  width: 7%;
}

table .w-8 {
  width: 8%;
}

table .w-9 {
  width: 9%;
}

table .w-10 {
  width: 10%;
}

table .w-11 {
  width: 11%;
}

table .w-12 {
  width: 12%;
}

table .w-13 {
  width: 13%;
}

table .w-14 {
  width: 14%;
}

table .w-15 {
  width: 15%;
}

table .w-20 {
  width: 20%;
}

table .w-25 {
  width: 25%;
}

table .w-30 {
  width: 30%;
}

table .w-35 {
  width: 35%;
}

table .w-40 {
  width: 40%;
}

table .w-45 {
  width: 45%;
}

table .w-50 {
  width: 50%;
}

table .w-55 {
  width: 55%;
}

table .w-60 {
  width: 60%;
}

table .w-65 {
  width: 65%;
}

table .w-70 {
  width: 70%;
}

table .w-75 {
  width: 75%;
}

table .w-80 {
  width: 80%;
}

table .w-85 {
  width: 85%;
}

table .w-90 {
  width: 90%;
}

table .w-95 {
  width: 95%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncateWrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-primary {
  color: #004666 !important;
}


.c-btn--danger {
  border: 1px solid #dc3545;
  background-color: #dc3545;
  color: #fff;

  &:hover, &:focus {
    border-color: #dc3545;
    background-color: #dc3545;
    color: #fff;
  }
}

.c-btn--danger {
  &.c-btn--link.c-btn--round .c-btn__icon, &.c-btn--icon-only.c-btn--round .c-btn__icon {
    font-size: 1.33333rem;
  }

  &.c-btn--link.c-btn--round .c-btn__icon::before, &.c-btn--icon-only.c-btn--round .c-btn__icon::before {
    padding: 9px;
    border-radius: 50%;
  }

  &.c-btn--link.c-btn--round.c-btn--small .c-btn__icon, &.c-btn--icon-only.c-btn--round.c-btn--small .c-btn__icon {
    font-size: 1rem;
  }

  &.c-btn--link.c-btn--round.c-btn--small .c-btn__icon::before, &.c-btn--icon-only.c-btn--round.c-btn--small .c-btn__icon::before {
    padding: 4px;
    border-radius: 50%;
  }

  &.c-btn--link .c-btn__icon, &.c-btn--icon-only .c-btn__icon {
    font-size: 1.33333rem;
    color: #fff;
  }

  &.c-btn--link .c-btn__icon::before, &.c-btn--icon-only .c-btn__icon::before {
    padding: 9px;
    border-radius: 0;
  }

  &.c-btn--link .c-btn__icon::before, &.c-btn--icon-only .c-btn__icon::before {
    border: 1px solid #dc3545;
    background-color: #dc3545;
  }

  &.c-btn--link.c-btn--small .c-btn__icon, &.c-btn--icon-only.c-btn--small .c-btn__icon {
    font-size: 1rem;
  }

  &.c-btn--link.c-btn--small .c-btn__icon::before, &.c-btn--icon-only.c-btn--small .c-btn__icon::before {
    padding: 0;
    border-radius: 0;
  }
}
