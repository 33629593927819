hr {
  opacity: 1;
}

.popover-header{
  color: rgba(0, 70, 102); 
}

.btn-close{
  box-shadow: none!important;
}

//
// Styles for Material Design
//

.mat-calendar-body-selected {
  background-color: rgba(0, 70, 102, 0.9);
  color: #fff;
}

/*.mat-calendar-body-hover {
  background-color: rgba(0, 70, 102, 0.9);
  color: #fff;
}*/


.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 99, 132, 0.5);
}

.mat-button, .mat-icon-button, .mat-stroked-button {  
  background: rgba(0, 70, 102, 0);
}

.mat-button-focus-overlay {  
  background: rgba(0, 70, 102);  
}

.mat-calendar-period-button {
  font-family: "vwfs-sans", sans-serif;  
}

.mat-calendar {
  font-family: "vwfs-sans", sans-serif;  
}

//
// Other styles
//

#login {
  padding-bottom: 1rem !important;
}

.c-page-head-nav__link-text {
  @media (min-width: 480px) {
    display: inline-block !important;
  }
}

.c-icon--\[control-burger\] {
  margin-left: 4px;
}

.inherit-flex {
  flex: 1;
  display: inherit;
  flex-direction: inherit;
}

.table-skatt {
  border: 0;
}

.c-modal {
  display: block;
}

.c-modal__content {
  li {
    text-align: left;
  }
}

span {
  &.is-error {
    color: #ff4963;
  }
}

small {
  &.is-error {
    color: #ff4963;
  }
}

.spinner-wrapper {
  position: relative;
  min-height: 150px;

  &.td-wrapper {
    height: 70px;
  }

  .c-section-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.link-separator {
  margin-left: 0.5rem;
}

@media (max-width: 721px) {
  .no-flex-mobile {
    display: inline-block !important;
    width: 100%;
  }

  .push-right-when-small {
    margin-right: 0px !important;
    margin-left: auto !important;
  }
}

[hidden] {
  display: none;
}

.super-user-menu-container {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}

.super-user-main-container {
  margin-left: 70px; /* Same as the width of the super-user-menu-container */
  @media (max-width: 720px) {
    margin-left: 70px;
  }
}

.page-margin {
  @media (min-width: 1430px) {
    padding-left: 75px;
  }

  @media (max-width: 1430px) {
    padding-left: 110px;
  }

  @media (max-width: 960px) {
    padding-left: 102px;
  }

  @media (max-width: 720px) {
    padding-left: 90px;
  }

  @media (max-width: 480px) {
    padding-left: 12px;
  }
}

.c-btn {
  &.is-disabled {
    pointer-events: auto !important;

    &:hover,
    &:focus {
      border-color: inherit !important;
      background-color: inherit !important;
    }

    span::after {
      display: none;
    }
  }
}

body {
  font-family: "vwfs-sans", sans-serif;
}

.toll-extraInfo {
  text-align: right;
  font-family: vwfs-sans;
  font-size: .83333rem;
  text-transform: uppercase;
  font-weight: 700;
}

.c-checkbox__label::before {
  // width: 28px;
  // height: 28px;
  // border: 1px solid #a8adb3;
  // font-size: 18px;
  // line-height: 28px;

  width: 1.75rem;
  height: 1.75rem;
  border: 0.063rem solid #a8adb3;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.c-checkbox__label::after {
  // width: 26px;
  // height: 26px;
  // top: 1px;
  // left: 1px;

  width: 1.5rem;
  height: 1.5rem;
  margin: 0.10rem;
  top: 0;
  left: 0;
}

.c-mega-menu {
  padding-bottom: 0%;
}
