.switch {
  font-size: 1rem;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(calc(2.375rem * 0.8) * 2);
      border-radius: calc(2.375rem * 0.8);
      height: calc(2.375rem * 0.8);
      line-height: calc(2.375rem * 0.8);
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(calc(2.375rem * 0.8) * 2) + 0.5rem);

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(calc(2.375rem * 0.8) * 2);
        bottom: 0;
        display: block;
      }

      &::before {
        right: 0;
        background-color: #fff;
        border: 1px solid #a8adb3;
        border-radius: calc(2.375rem * 0.8);
        transition: 0.2s all;
      }

      &::after {
        top: 2px;
        left: 2px;
        width: calc(calc(2.375rem * 0.8) - calc(2px * 2));
        height: calc(calc(2.375rem * 0.8) - calc(2px * 2));
        border-radius: 50%;
        background-color: #a8adb3;
        transition: 0.2s all;
        border: 4px solid #fff;
      }
    }

    &:checked + label {
      &::before {
        background-color: #004666;
        border: 1px solid #004666;
      }

      &::after {
        margin-left: calc(2.375rem * 0.8);
        background-color: #fff;
        border: 4px solid #004666;
      }
    }

    &:focus + label::before {
      outline: none;
      box-shadow: 0 0 0 0.1rem #66e4ee;
    }

    &:disabled + label {
      color: #868e96;
      cursor: not-allowed;
      &::before {
        background-color: #e9ecef;
      }
    }
  }

  &.switch-sm {
    font-size: 0.875rem;

    input {
      + label {
        min-width: calc(calc(1.9375rem * 0.8) * 2);
        height: calc(1.9375rem * 0.8);
        line-height: calc(1.9375rem * 0.8);
        text-indent: calc(calc(calc(1.9375rem * 0.8) * 2) + 0.5rem);

        &::before {
          width: calc(calc(1.9375rem * 0.8) * 2);
        }

        &::after {
          width: calc(calc(1.9375rem * 0.8) - calc(2px * 2));
          height: calc(calc(1.9375rem * 0.8) - calc(2px * 2));
        }
      }

      &:checked + label::after {
        margin-left: calc(1.9375rem * 0.8);
      }
    }
  }
  &.switch-lg {
    font-size: 1.25rem;

    input {
      + label {
        min-width: calc(calc(3rem * 0.8) * 2);
        height: calc(3rem * 0.8);
        line-height: calc(3rem * 0.8);
        text-indent: calc(calc(calc(3rem * 0.8) * 2) + 0.5rem);

        &::before {
          width: calc(calc(3rem * 0.8) * 2);
        }

        &::after {
          width: calc(calc(3rem * 0.8) - calc(2px * 2));
          height: calc(calc(3rem * 0.8) - calc(2px * 2));
        }
      }
      
      &:checked + label::after {
        margin-left: calc(3rem * 0.8);
      }
    }
  }
}